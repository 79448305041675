@media (min-width: 576px) {
  .container {
    max-width: inherit;
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1680px;
  }
}

.name-logo {
  width: 240px;
  height: 60px;
  object-fit: contain;
}

html {
  font-size: 16px;
}

body {
  color: #000000;
}

html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
}

h6.muted {
  color: #6c757d;
  text-transform: uppercase;
  font-weight: 300;
  margin-bottom: 1.5rem;
  font-size: 1rem;
}

a {
  color: #350569;
  text-decoration: none;
}

a:hover {
  color: #350569;
  text-decoration: underline;
}

.btn-primary {
  background-color: #0066f9;
  border: 1px solid #005ce0;
}

.btn {
  border-radius: 0.1rem;
}

.bo-input {
  font-weight: 400;
  font-size: 16px;
  padding: 12px 6px;
  padding-left: 10px;
  background-color: #F0EDF3 !important;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #F0EDF3 !important;
  width: 100%;
  box-shadow: none !important;
}

.bo-input::placeholder {
  color: rgba(0, 0, 0, 0.5) !important;
}

.bo-input-error {
  border: 1px solid #FF0000 !important;
}

.bo-eye-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  right: 0px;
  color: #979797;
  width: 50px;
  height: 50px;
}

.bo-eye-wrapper:hover {
  color: gray;
}

.bo-validation-error {
  margin-top: 2px;
  font-weight: 400;
  font-size: 10px;
  color: red;
  text-align: left;
}

.bo-text {
  font-weight: 400;
  font-size: 16px;
}

.bo-text-small {
  font-weight: 400;
  font-size: 12px;
  margin-bottom: 0;
}

.bo-text-xs {
  font-size: 9px;
  margin-bottom: 0;
}

.bo-consent-text {
  font-weight: 400;
  font-size: 12px;
}

.bo-text-bold {
  font-weight: 600;
  font-size: 16px;
}

.bo-text-large {
  font-size: 18px;
}

.bo-larger {
  font-size: 18px;
  font-weight: 600;
}

.bo-larger-x2 {
  font-size: 21px;
  font-weight: 600;
}

.bo-larger-x3 {
  font-size: 24px;
  font-weight: 600;
}

.bo-text-red {
  color: red;
}

.bo-text-black {
  color: black;
}

.bo-link {
  color: #350569 !important;
  text-decoration: underline !important;
  cursor: pointer;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 400;
  font-size: 16px;
}

.bo-link:hover {
  color: #9f9cac !important;
}

.bo-link:active {
  background-color: #e5e3ec;
  border-radius: 5px;
}

.bo-button-100 {
  width: 100px;
}

.bo-header {
  font-weight: 600;
  font-size: 24px;
}

.bo-button {
  outline: none !important;
  border: rgb(234, 230, 239) solid 2px !important;
  background-color: rgb(234, 230, 239) !important;
  border-radius: 500px;
  font-weight: 600;
  font-size: 18px;
  color: #350569 !important;
  padding: 10px;
}

.bo-button:hover {
  background-color: rgba(213, 206, 223) !important;
  border: rgb(213, 206, 223) solid 2px !important;
  color: #350569;
}

.bo-button:focus {
  background-color: rgba(53, 5, 105, 0.2) !important;
  box-shadow: none !important;
}

.bo-button:disabled {
  color: rgba(53, 5, 105, 0.75) !important;
}

.bo-button-outline {
  border: #350569 solid 2px !important;
  background-color: transparent !important;
  border-radius: 500px;
  font-weight: 600;
  font-size: 18px;
  color: #350569 !important;
  padding: 10px;
}

.bo-button-outline:hover {
  background-color: rgb(53, 5, 105) !important;
  color: white !important;
}

.bo-w-25 {
  width: 25px;
}

.bo-w-30 {
  width: 30px;
}

.bo-w-40 {
  width: 40px;
}

.bo-w-50 {
  width: 50px;
}

.bo-w-65 {
  width: 65px;
}

.bo-w-80 {
  width: 80px;
}

.bo-w-100 {
  width: 100px;
}

.bo-w-120 {
  width: 120px;
}

.bo-w-150 {
  width: 150px;
}

.bo-w-250 {
  width: 250px;
}

.bo-min-w-200 {
  min-width: 200px;
}

.bo-h-30 {
  height: 30px;
}

.bo-button-inline {
  height: 30px;
  font-weight: 500;
  font-size: 12px;
  background-color: #5946A4;
  border-radius: 0.5rem;
}

.bo-button-inline:focus {
  background-color: #5946A4;
  box-shadow: 0 0 0 .2rem #8988C5 !important
}

.bo-button-inline-link {
  font-weight: 500;
  font-size: 14px;
  color: #5946A4;
}

.bo-button-inline-link:hover {
  color: #5946A4;
}

.bo-same-line {
  display: flex;
  justify-content: left;
  align-items: center;
}

.bo-inline-badge {
  font-size: 12px
}

.bo-badge {
  font-size: 14px
}

.bo-pointer {
  cursor: pointer;
}

.bo-mobile {
  max-width: 440px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 !important;
}

.bo-rounded-container {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: white;
  border-top-left-radius: 32px;
  border-top-right-radius: 32px;
}

.bo-code-container {
  display: block;
}

.bo-code-input {
  width: 44px !important;
  height: 50px;
  padding: 0;
  font-size: 20px;
  text-align: center;
  margin: 4px;
  text-transform: uppercase;
  color: #000000;
  border: 2px solid rgb(240, 237, 243);
  border-radius: 5px;
  background: rgb(240, 237, 243);
  background-clip: padding-box;
}

.bo-code-input:focus {
  appearance: none;
  outline: 0;
  border: 2px solid rgb(53, 5, 105);
}

.bo-code-input::-webkit-outer-spin-button,
.bo-code-input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.bo-payment-error-container {
  background-color: rgba(255, 241, 0, 0.22);
  color: red;
  border-radius: 5px;
  text-align: center;
  padding: 8px;
  border-style: solid;
  border-width: 1px;
  border-color: #000000;
  margin-bottom: 16px;
}

.bo-switch-scale {
  width: 0px;
  transform: scale(1.5) translate(25%, 0%);
  box-shadow: none !important;
}

.bo-switch-scale:focus {
  border: #350569 solid 1px;
  --bs-form-switch-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='rgb(53, 5, 105)'/></svg>") !important;
}

.bo-switch-scale:checked {
  background-color: #350569;
  border: #350569 solid 1px;
  --bs-form-switch-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='white'/></svg>") !important;
}

.bo-select {
  font-weight: 400;
  font-size: 16px;
  padding: 12px 6px;
  padding-left: 10px;
  background-color: #F0EDF3 !important;
  color: #000000;
  border-radius: 5px;
  border: 1px solid #F0EDF3 !important;
  box-shadow: none !important;
  height: 50px;
}

.bo-card-container {
  padding: 10px;
  min-width: 200px;
  max-width: 340px;
  border-radius: 0.4rem;
  border-color: #6C757D;
  border-width: 1px;
  border-style: solid;
}

.bo-bg-gray {
  background-color: #f6f6f6;
}

.bo-bt-element-container {
  height: 50px;
  margin-bottom: 8px;
  border: 1px solid #F0EDF3 !important;
  background-color: #F0EDF3 !important;
  border-radius: 5px;
  padding: 12px 6px;
}

.bo-bt-element-container-invalid {
  border-color: red !important;
}

.bo-bt-element-w-100px {
  width: 100px;
}

.bo-card-form-default-input {
  width: 100%;
  height: 50px;
  margin-bottom: 8px;
  border: 1px solid #F0EDF3 !important;
  background-color: #F0EDF3 !important;
  border-radius: 5px;
  padding: 12px 6px;
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}

.bo-card-form-default-input-invalid {
  border-color: red !important;
}

.bo-card-form-default-input::placeholder {
  color: #979797;
}

.bo-card-form-default-input::-webkit-input-placeholder {
  color: #979797;
}

.bo-card-form-default-input:-moz-placeholder {
  color: #979797;
}

.bo-card-form-default-input::-ms-placeholder {
  color: red;
}

.bo-card-form-default-input:focus {
  outline: none;
}

.bo-card-form-default-input:disabled, 
.bo-card-form-default-input:read-only  {
  color: rgba(0, 0, 0, 0.8);
}

.bo-card-form-name-input {
  width: 49%;
}

.bo-card-form-city-input {
  width: 48%;
}

.bo-card-form-state-input {
  width: 20%;
}

.bo-card-form-zip-input {
  width: 30%;
}

.bo-card-form-default-input-readonly {
  background-color: #EFEFEF;
  border-color: #EFEFEF;
}